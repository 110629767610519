import React, { useState, useEffect, useRef, useCallback } from 'react';

import axios from 'axios';
import {
  Button, IconButton,
} from '@mui/material';
import {
  KeyboardVoice as KeyboardVoiceIcon,
  StopCircle as StopCircleIcon,
  HourglassTop as HourglassTopIcon,
} from '@mui/icons-material';

const API_URL = window?.config?.apiUrl || '/api';

const Recorder = ({ onChange }) => {
  const [recording, setRecording] = useState(false);
  const [loading, setLoading] = useState(false);
  const mediaRecorderRef = useRef();
  const resetTimerRef = useRef();
  const chunksRef = useRef([]);
  const audioRef = useRef();

  useEffect(() => {
    const run = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/webm' });
        mediaRecorderRef.current.ondataavailable = (e) => {
          if (e.data && e.data.size > 0) {
            chunksRef.current.push(e.data);
          }
        };
        mediaRecorderRef.current.onstop = async () => {
          try {
            setLoading(true);
            const audioBlob = new Blob(chunksRef.current, { type: 'audio/webm' });
            chunksRef.current = [];
            const res = await axios.post(
              `${API_URL}/groceries`,
              { file: Array.from(new Uint8Array(await audioBlob.arrayBuffer())) },
              { headers: { 'content-type': 'application/json' } },
            );
            if (res?.data?.title && res?.data?.price) {
              onChange(res.data);
            }
          } catch (e) {
            console.log('Error making request:', e);
          } finally {
            setLoading(false);
          }
        };
      } catch(error) {
        console.log('Error accessing user media:', error);
        setLoading(false);
      }
    };
    run();
  }, []);

  const handleRecordClick = useCallback(() => {
    if (!recording) {
      mediaRecorderRef.current.start();
      setRecording(true);
      resetTimerRef.current = setTimeout(() => {
        mediaRecorderRef.current.stop();
        setRecording(false);
      }, 10000);
    } else {
      clearTimeout(resetTimerRef.current);
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  }, [recording]);

  return (
    <>
    <Button variant="contained" color="primary" sx={{ borderRadius: 100, width: 70, height: 70 }} >
      <IconButton onClick={handleRecordClick} sx={{ color: '#fff'}}>
        {(!loading && recording) && <StopCircleIcon />}
        {(!loading && !recording) && <KeyboardVoiceIcon />}
        {loading && <HourglassTopIcon />}
      </IconButton>
    </Button>
    <audio style={{ display: 'none' }} ref={audioRef} controls />
    </>
  )
};

export default Recorder;
