
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import './index.css';
import {
  AppBar, 
  IconButton, 
  List,
  Stack,
  Toolbar,
  Typography,
  ListItemText,
  ListItem,
  ListItemAvatar,
  Avatar,
  TextField,
  Divider,
  SwipeableDrawer,
  Box,
  MenuItem,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Refresh as RefreshIcon,
  Folder as FolderIcon,
  Delete as DeleteIcon,
  ShoppingBasket as ShoppingBasketIcon,
  KeyboardVoice as KeyboardVoiceIcon,
  StopCircle as StopCircleIcon,
  HourglassTop as HourglassTopIcon,
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveCircleIcon,
  ChecklistRtl,
  PlaylistAdd,
} from '@mui/icons-material';
import Recorder from './Recorder';
import { v4 as uuidV4 } from 'uuid';
import sAgo from 's-ago';

const getStorageItem = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    return null;
  }
};

const setStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.warn(e);
  }
};

const DEFAULT_STATE = {
  counter: 0,
  groceryLists: {},
};

const getTotal = (items) => {
  return (items || [])
    .map(i => (+i.price || 0) * (+i.qty || 0))
    .filter(item => !!item)
    .reduce((a, b) => a + b, 0)
};

const App = () => {
  const [appState, setAppState] = useState(() => getStorageItem('state') || DEFAULT_STATE); 
  const [activeListId, setActiveListId] = useState(() => uuidV4());
  const [newItem, setNewItem] = useState({ title: '', price: 0 });
  const [items, setItems] = useState([]);
  const [listTitle, setListTitle] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    setStorageItem('state', appState);
  }, [appState]);

  useEffect(() => {
    setAppState((prevState) => {
      let existingList = prevState?.groceryLists?.[activeListId];
      let counter = prevState.counter;
      if (!existingList && items.length) {
        existingList = {
          title: `List #${++counter}`,
          items,
          created: Date.now(),
        };
      }
      return {
        ...prevState,
        counter,
        groceryLists: {
          ...prevState.groceryLists,
          ...(!!existingList && {
            [activeListId]: {
              ...existingList,
              items,
            },
          })
        }
      };
    });
  }, [items, activeListId]);

  
  useEffect(() => {
    setItems(appState?.groceryLists?.[activeListId]?.items || [])
  }, [activeListId]);

  useEffect(() => {
    setListTitle(appState?.groceryLists?.[activeListId]?.title || 'Grocery List')
  }, [activeListId, appState]);

  const total = useMemo(() => (
    getTotal(items)
  ), [items]);

  const groceryListMenu = useMemo(() => {
    const listMenu =  Object.entries(appState.groceryLists).filter(([id, val]) => !!val).map(([id, item]) => ({ id, ...item }));
    listMenu.sort((list0, list1) => list1.created - list0.created);
    return listMenu;
  }, [appState.groceryLists]);

  const handleItemAdd = useCallback(() => {
    setItems((prevItems) => [...prevItems, ({ ...newItem, qty: 1 })])
    setNewItem({ title: '', price: 0 });
  }, [newItem]);

  const handleItemDelete = useCallback((index) => ({ title, price }) => {
    setItems((prevItems) => prevItems.filter((_, i) => i !== index))
  }, []);

  const handleTitleChange = useCallback((event) => {
    setNewItem((oldItem) => ({ ...oldItem, title: event.target.value }))
  }, []);

  const handlePriceChange = useCallback((event) => {
    setNewItem((oldItem) => ({ ...oldItem, price: event.target.value }))
  }, []);

  const handleItemQtyInc = useCallback((index) => () => (
    setItems((prevItems) => prevItems.map((item, i) => (
      i === index ? { ...item, qty: item.qty + 1 } : item
    )))
  ), []);


  const handleItemQtyDec = useCallback((index) => () => (
    setItems((prevItems) => prevItems.map((item, i) => (
      i === index ? { ...item, qty: Math.max(item.qty - 1, 1) } : item
    )))
  ), []);

  const handleCreateNewList = useCallback(() => {
    setActiveListId(uuidV4());
  }, []);

  const handleDeleteListById = useCallback((id) => () => {
    if (!window.confirm('Delete list?')) {
      return;
    }
    setAppState((prevState) => ({
      ...prevState,
      groceryLists: {
        ...prevState.groceryLists,
        [id]: null,
      },
    }))
  }, []);

  const isValid = useMemo(() => (
    newItem.title
    && +newItem.price
    && Number.isFinite(+newItem.price) 
    && +newItem.price > 0
  ), [newItem]);

  debugger;
  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Stack direction="row" justifyContent="center" width="100%">
            <Typography variant="h6" align="center">
              {listTitle || 'Grocery List'}
            </Typography>
          </Stack>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleCreateNewList}
          >
            <AddCircleIcon />
          </IconButton>
        </Toolbar>
        <SwipeableDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          <Stack minWidth="200px" padding={2}>
            <ListItem onClick={handleCreateNewList}>
              <ListItemAvatar>
                <Avatar>
                  <PlaylistAdd />
                </Avatar>
              </ListItemAvatar>
              <Typography>New List</Typography>
            </ListItem>
            <Divider />
            {(groceryListMenu || []).map((groceryList) => (
              <ListItem
                onClick={() => setActiveListId(groceryList.id)}
                secondaryAction={(
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={handleDeleteListById(groceryList.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              >
                <ListItemAvatar>
                  <Avatar>
                    <ChecklistRtl />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={[groceryList.title, getTotal(groceryList.items).toFixed(2)].join('  |  ')}
                  secondary={`Created: ${sAgo(new Date(groceryList.created || 0))}`}
                />
              </ListItem>
            ))}
          </Stack>
        </SwipeableDrawer>
      </AppBar>
      
      <Stack height="100vh">
        <div style={{ marginTop: 56 }} />
        <Stack direction="row" justifyContent="space-between" sx={{ m: 2 }}>
          <Typography variant="h4">Total</Typography>
          <Typography variant="h4">{total.toFixed(2)}</Typography>
        </Stack>
        <Divider />
        <Stack flexGrow={1} sx={{ overflowY: 'auto' }}>
          <List>
            {items.map((item, index) => (
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={handleItemDelete(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <ShoppingBasketIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={item.title}
                  secondary={(+item.price).toFixed(2)}
                />
                <Stack direction="row" flexGrow={1} justifyContent="end" alignItems="center">
                  <Stack direction="row" alignItems="center">
                    <IconButton
                      onClick={handleItemQtyDec(index)}
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                    <Typography>{item?.qty?.toFixed(0)}</Typography>
                    <IconButton
                      onClick={handleItemQtyInc(index)}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Stack>
                  <Typography
                    sx={{
                      width: 50,
                      ml: 2,
                    }}
                  >
                    {(item.qty * item.price).toFixed(2)}
                  </Typography>
                </Stack>
              </ListItem>
            ))}
          </List>
        </Stack>
        <Divider />
        <Stack direction="row" spacing={1} alignItems="center" sx={{ m: 2 }}>
          <TextField
            label="Title"
            variant="outlined"
            value={newItem.title}
            onChange={handleTitleChange} 
            multiline
            fullWidth
          />
          <TextField
            label="Price"
            variant="outlined"
            type="number"
            value={newItem.price}
            style={{ maxWidth: 100 }}
            onChange={handlePriceChange} 
          />
          <IconButton onClick={handleItemAdd} disabled={!isValid}>
            <AddCircleIcon />
          </IconButton>
        </Stack>
          <Stack direction="row" spacing={2} justifyContent="center" sx={{ mb: 2 }}>
            <Recorder onChange={(item) => setNewItem(item)}/>
          </Stack>
      </Stack>
    </>
        
  );
};

export default App;
